@media screen and (min-width: 860px) {
  .nd-container-body-map {
    display: flex;
    position: relative;
  }
  .nd-body-map-container {
    width: 30%;
    padding: 10px 0;
  }

  .nd-hover-area {
    &:hover {
      path {
        fill: #144e85;
        opacity: 0.85;
      }
    }
  }


  .nd-hover-location {
    fill: #144e85 !important;
    opacity: 0.85 !important;
  }

}


@media screen and (max-width: 860px) {

  .nd-body-map-container {
    text-align: end;
    min-height: 440px;



  }

}

.nd-container-body-map {
  position: relative;

}

.nd-label-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.nd-selected-locations-label {
  color: #FFF;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.06px;
  text-transform: capitalize;
  border-radius: 7px;
  background: #064491;
  padding: 6px 14px;
  width: fit-content;
  margin-bottom: 10px;

}

#tooltip {
  border-radius: 8px;
  background-color: cornflowerblue;
  color: white;
  padding: 3px 5px;
  position: absolute;
  display: none;
}

.nd-show-full-body-btn {

  all: initial;
  cursor: pointer;
  border-radius: 6px;
  background: #F2F8FF;
  padding: 3px 13px;
  position: absolute;
  right: 5px;
  top: 5px;

  //
  //&:hover{
  //  &:after{
  //    content: 'Reset';
  //    border-radius: 8px;
  //    background-color: cornflowerblue;
  //    color: white;
  //    padding: 5px 30px;
  //    position: absolute;
  //    right: 50px;
  //    top: 10px;
  //  }
  //}
}


.nd-selected-area {
  path {
    opacity: 0.85;
    fill: #144e85 !important;
  }

}

.nd-selected-location {
  opacity: 0.85;
  fill: #144e85 !important;
}




.nd-disable-area{
  opacity: 0.2;
  fill: whitesmoke;
}

.nd-restriction{
  opacity: 0.8 !important;
  fill: whitesmoke !important;
}
